import { Component, Input, OnInit } from '@angular/core'
import { SharedService } from 'app/_services/shared.service'
import dayjs from 'dayjs'
import { BsDatepickerConfig, BsLocaleService, BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { map } from 'rxjs/operators'
import { DatePickerComponent } from '../date-picker/date-picker.component'
import { InputValidationDirective } from '../directives/input-validation.directive'
import { NgIf, PercentPipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FormFloatingComponent } from '../form/form-floating/form-floating.component'
import { calculateStartMargin } from 'app/_helpers/startMargin'
import { parseIafFactor } from 'app/_helpers/factors'
import { I18nDirective } from '../directives/app-i18n.directive'
import { firstValueFrom } from 'rxjs'
import { I18nService, MessageGetter } from 'app/_services/i18n.service'
@Component({
    selector: 'form-date',
    templateUrl: './form-date.component.html',
    standalone: true,
    imports: [
        FormFloatingComponent,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        BsDatepickerModule,
        InputValidationDirective,
        DatePickerComponent,
        PercentPipe,
        I18nDirective,
    ],
})
export class FormDateComponent implements OnInit {
    @Input() formOrderwork
    @Input() company
    @Input() showDiscount
    shouldShowDiscount

    get formEstimate() {
        return this.formOrderwork.get('estimate')
    }
    get formEstimateStart() {
        return this.formEstimate.get('start')
    }
    get formEstimateStartCertainty() {
        return this.formEstimate.get('startCertainty')
    }
    get formEstimateStartCertaintyDates() {
        return this.formEstimate.get('startCertaintyDates')
    }
    get formEstimateStartMargin() {
        return this.formEstimate.get('startMargin')
    }

    // Shortcut
    readonly i18n: MessageGetter = this.i18nService.getMessage.bind(this.i18nService)
    get languageId() {
        return this.i18nService.languageId
    }

    // Init in a week
    dateInit
    dateConfig

    // From today until in 5 years
    dateFrom = new Date().setHours(0, 0, 0, 0)
    dateTo = new Date().setFullYear(new Date().getFullYear() + 5)

    constructor(
        public bsDatepickerConfig: BsDatepickerConfig,
        private localeService: BsLocaleService,
        private sharedService: SharedService,
        private i18nService: I18nService
    ) {}

    ngOnInit() {
        this.shouldShowDiscount = this.showDiscount && parseIafFactor(this.company.factors.iaf.dateDiscount, this.formOrderwork.value)
        this.localeService.use(this.languageId)
        this.bsDatepickerConfig = {
            ...this.bsDatepickerConfig,
            containerClass: 'theme-default',
            showWeekNumbers: false,
            selectFromOtherMonth: true,
            rangeInputFormat: 'DD-MM-YYYY',
            displayOneMonthRange: true,
            customTodayClass: 'bs-datepicker-config-today',
            minDate: new Date(),
        }

        // Listen for changes on startCertainty and set date accordingly
        this.formEstimateStartCertainty.valueChanges.subscribe((valueNew) => {
            const valueOld = this.formEstimate.value.startCertainty
            if (valueOld === 'estimate' && valueNew !== 'estimate') this.formEstimate.get('startCertaintyDates').patchValue([])

            // From unknown to a known date
            if (valueOld === 'unknown' && valueNew !== 'unknown') {
                this.dateSetDefault()
            }
            if (valueNew === 'unknown') {
                this.dateSetDefault()
                this.formEstimateStart.patchValue({ unix: this.dateInit })
            }
        })

        this.formEstimateStart.valueChanges.subscribe((valueNew) => {
            const startMargin = calculateStartMargin(valueNew)
            this.formEstimateStartMargin.patchValue(startMargin)
        })

        // If startvalue is defined
        const startValue = this.formEstimateStart
        if (startValue && startValue.value && startValue.value.unix) this.dateInit = new Date(+startValue.value.unix)
        else this.dateSetDefault()
        if (this.shouldShowDiscount) this.getDateConfig()
    }

    get checkStartCertaintyVisible() {
        const { start } = this.formEstimate.value
        if (!start.unix) return false
        return true
    }

    async getDateConfig() {
        this.dateConfig = await firstValueFrom(
            this.sharedService.getDateConfig(this.company.name.id, this.formOrderwork.value._id).pipe(map((r) => r.data))
        )
    }

    getCurrentDateConfig() {
        if (!this.dateConfig) return {}
        const unix = this.formEstimateStart.value.unix
        const date = dayjs(+unix).format('YYYY-MM-DD')
        return this.dateConfig[date] || {}
    }

    dateSetDefault() {
        this.dateInit = this.unixAtNine(new Date())
    }

    unixAtNine(date) {
        return dayjs(date).startOf('day').set('hour', 9).valueOf()
    }
}
