import { Injectable } from '@angular/core'
import { BehaviorSubject, filter, firstValueFrom, map, take, timeout } from 'rxjs'
import { ReqOptions, RequestService } from './request.service'
import { get } from 'lodash'

@Injectable({
    providedIn: 'root',
})
export class I18nService {
    private readySource = new BehaviorSubject<boolean>(false)
    public readyObservable = this.readySource.asObservable()
    public get isReady() {
        return this.readySource.value
    }
    private set isReady(value) {
        this.readySource.next(value)
    }

    private messagesSource = new BehaviorSubject<LocaleMessages>(null)
    public messages = this.messagesSource.asObservable()

    readonly idLocaleMap: IdLocaleMap = {
        nl: 'nl-NL',
        en: 'en-US',
    }
    readonly localeIdMap: LocaleIdMap = {
        'nl-NL': 'nl',
        'en-US': 'en',
    }

    private _locale: LocaleOption = 'nl-NL'
    public get locale() {
        return this._locale
    }
    public set locale(value) {
        // Don't do anything if value is the same
        if (value === this._locale) return

        // Check if locale is valid
        if (!this.isLocaleValid(value)) {
            console.error(`Invalid locale: ${value}`)
            return
        }

        this._locale = value
        this.useLocale(value)
    }

    public get languageId() {
        return this.localeIdMap[this.locale]
    }

    /** Offline data */
    private base: LocaleMessages
    private languageExtensions: MessageExtensions = {}

    /** Prevent logging the same message multiple times */
    private logCache: { [key: string]: { log?: boolean; warn?: boolean; error?: boolean } } = {}

    constructor(private requestService: RequestService) {
        this.init()
    }

    private async init() {
        // Get default
        this.base = await this.fetchBase()
        const { locale } = this.base
        this.languageExtensions['base'] ??= {}
        this.languageExtensions['base'][locale] = this.base
        this.messagesSource.next(this.base)

        // Get from remote
        const res = await firstValueFrom(this.getLanguage())
        const extension = { current: res.data }
        Object.assign(this.languageExtensions, extension)

        this.isReady = true

        this.useLocale(this.locale)
    }

    private isLocaleValid(locale: LocaleOption) {
        if (!this.localeIdMap[locale]) {
            console.error(`Invalid locale: ${locale}`)
            return false
        }
        return true
    }

    async fetchBase() {
        return await fetch(`assets/locale/messages.json`).then((response) => {
            if (!response.ok) {
                throw new Error('HTTP error ' + response.status)
            }
            return response.json()
        })
    }

    /** Shortcut that handles concatenated keys */
    private getMessageFromExtension(key: string, locale: LocaleOption, extensionId: LanguageExtensionOption = 'current') {
        if (!key) return this.languageExtensions?.[extensionId]?.[locale]?.translations
        return get(this.languageExtensions?.[extensionId]?.[locale]?.translations, key)
    }

    // Overloads
    /** Get translation package */
    getMessage(key: FalsyString, locale?: LocaleOption): Translations
    /** Get translation string with fallbacks */
    getMessage(key: string, locale?: LocaleOption): string

    /** Get translation with fallbacks */
    getMessage(key: string, locale = this.locale) {
        const message = this.getMessageFromExtension(key, locale)
        if (message || message === '') return message

        // Fallback on base, if not found in locale
        const messageBase = this.getMessageFromExtension(key, locale, 'base')
        if (messageBase || messageBase === '') {
            if (this.isReady) {
                this.logOnce(
                    `No message found for key: ${key} in locale: ${locale}
                    falling back on base.`,
                    'warn'
                )
            } else {
                this.logOnce(`I18nService not ready yet`, 'warn')
            }

            return messageBase
        }

        if (this.isReady) {
            this.logOnce(`No message found for key: ${key} in locale: ${locale}`, 'error')
        } else {
            this.logOnce(`I18nService not ready yet`, 'warn')
        }

        return null
    }

    private useLocale(locale: LocaleOption) {
        this._locale = locale

        const messages = this.languageExtensions['current']?.[locale]
        if (messages) return this.messagesSource.next(messages)
        if (!this.isReady) return this.logOnce(`I18nService not ready yet`, 'warn')
        this.logOnce(`No messages found for locale ${locale}`, 'error')
    }

    getLanguage(reqOpts: ReqOptions = null) {
        const url = `/api/language`
        return this.requestService.get(url, {}, reqOpts)
    }

    /** Triggers `callback` immediatly if service is ready, otherwise waits until service is ready to trigger `callback`. Returns a promise that either resolves instantly or resolves as soon as service is ready. */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async onReady(callback: () => any = () => null, timeoutMs = 5000) {
        if (this.isReady) return callback()

        return firstValueFrom(
            this.readyObservable.pipe(
                timeout({ each: timeoutMs, with: () => Promise.reject('I18nService not ready after timeout') }),
                filter(Boolean),
                take(1),
                map(() => callback())
            )
        )
    }

    logOnce(message: string, type: 'log' | 'warn' | 'error' = 'log') {
        if (this.logCache[message]?.[type]) return
        this.logCache[message] ??= {}
        this.logCache[message][type] = true

        console[type](message)
    }
}

export type LocaleShort = 'nl' | 'en'
export type LocaleOption = 'nl-NL' | 'en-US'
type IdLocaleMap = { [key in LocaleShort]: LocaleOption }
type LocaleIdMap = { [key in LocaleOption]: LocaleShort }
export type LayerOption = 'base' | 'company' | 'extension'
export type LanguageExtensionOption = 'current' | 'base'

interface Translations {
    [key: string]: string
}

interface LocaleMessages {
    locale: LocaleOption
    translations: Translations
}

/** Reflects relevant layers for current configuration */
type MessageExtensions = {
    [key in LanguageExtensionOption]?: { [key in LocaleOption]?: LocaleMessages }
}

/** Reflects data from server */
export interface LanguageLayer extends LocaleMessages {
    layer: LayerOption
    companyRef?: { companyId: string }
    extensionId?: 'release' | 'production'
    createdAt: string
    updatedAt: string
    __v: number
    _id: string
}

/** Reflects data from server */
export type LanguageData = {
    [key in LayerOption]: { [key in LocaleOption]: LanguageLayer }
}

type FalsyString = '' | null | undefined
export interface MessageGetter {
    (key: FalsyString, locale?: LocaleOption): Translations
    (key: string, locale?: LocaleOption): string
}
