import { Component, forwardRef, Input } from '@angular/core'
import { FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { distinctUntilChanged } from 'rxjs/operators'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TippyDirective } from '../directives/tippy.directive'
import { InputValidationDirective } from '../directives/input-validation.directive'
import { FormFloatingComponent } from '../form/form-floating/form-floating.component'
import { NgIf } from '@angular/common'
import { FormEmailComponent } from '../form-email/form-email.component'
import { I18nDirective } from '../directives/app-i18n.directive'
import { I18nService, MessageGetter } from 'app/_services/i18n.service'

@Component({
    selector: 'form-customer',
    templateUrl: './form-customer.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormCustomerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormCustomerComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        FormFloatingComponent,
        FormsModule,
        InputValidationDirective,
        ReactiveFormsModule,
        TippyDirective,
        FontAwesomeModule,
        FormEmailComponent,
        I18nDirective,
    ],
})
export class FormCustomerComponent {
    @Input() mobileText = true
    @Input() admin = false
    @Input() formType = 'full'
    @Input() orderType = ''
    @Input() hasEmail = true

    isValid: boolean
    isCompanyRemoval: boolean
    form

    readonly i18n: MessageGetter = this.i18nService.getMessage.bind(this.i18nService)

    constructor(private fb: FormBuilder, private i18nService: I18nService) {}

    public writeValue(customer) {
        // Create form
        this.isCompanyRemoval = customer.accountManager?.name || customer.company?.name || customer.company?.kvk
        this.initForm(customer)

        // Listen for values in form
        this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(this.doPropagateChange.bind(this))
    }

    public validate() {
        return this.form.invalid ? { customer: true } : null
    }

    public registerOnChange(fn: any) {
        this.propagateChange = fn
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public registerOnTouched() {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private propagateChange = (_: unknown) => {}

    private doPropagateChange() {
        const propagate = this.form.value

        // Propagate form change
        this.propagateChange(propagate)
    }

    initForm(customer) {
        const email = customer.email || [{ value: '' }]
        const mobile = customer.mobile || [{ value: '' }]
        const emailValidators = this.hasEmail ? [Validators.required, Validators.email] : []
        this.form = this.fb.group({
            _id: [customer._id],

            name: this.fb.group({
                first: [customer.name?.first, [Validators.required]],
                middle: [customer.name?.middle],
                last: [customer.name?.last, [Validators.required]],
            }),

            company: this.fb.group({
                name: [customer.company?.name],
                kvk: [customer.company?.kvk],
            }),

            email: this.fb.array(
                email.map((email) =>
                    this.fb.group({
                        value: [email.value, emailValidators],
                    })
                )
            ),

            mobile: this.fb.array(
                mobile.map((mobile) =>
                    this.fb.group({
                        value: [mobile.value, [Validators.required, Validators.minLength(8)]],
                    })
                )
            ),

            communicationPreference: [customer.communicationPreference],

            accountManager: this.fb.group({
                name: [customer.accountManager?.name],
            }),

            language: [customer.language],
        })
    }
}
